import React from "react";
import Slider from "react-slick";

const SliderCommon = ({ images }) => {
  // responsive slider element

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "50px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {/* slider array */}
        {images.map((item, i) => (
          <img key={i} src={item} alt="" />
        ))}
      </Slider>
    </div>
  );
};

export default SliderCommon;
